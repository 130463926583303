import React from "react";
import MobileImage from "../../assets/images/Login/dizzy-payment-card-with-coins.gif";
import Coin3 from "../../assets/images/Login/coin-3.png";
import Coin5 from "../../assets/images/Login/coin-5.png";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/bitcash-logo.png";
import BannerImage from "../../assets/images/close-up-hand-holding-coin.jpg"
import Crypto from "../../assets/images/crypto.jpg"
import { TiTick } from "react-icons/ti";

export const PrivacyPolicy = () => {
  
  return (
    <div>
      <div>

        <div>
            <div className="d-flex justify-content-between mt-3">
            <div className="logo-wrapper">
                <img src={Logo} alt=""/>
            </div>
            <div className="d-flex justify-content-evenly">
              
            </div>
            {/* <div className="huelse-drexg">
                <Link to="/login">Login</Link>
            </div> */}
            </div>
        </div>

     


        <div className="container">
          
          <div className="row">
          
            <div className="col-lg-12">
              <div className="">
              <div className="login__heding-box">
                  <h1 className="fs-40 sdlkfdjl-2" style={{marginBottom:"20px",marginTop:"40px"}}>Privacy <span style={{ color: "#e9c147" }}>Policy</span></h1>
                  <p style={{textAlign: "justify",color:"white"}}>This privacy policies are made for the user of BITCASH to know about their own benefits & even to co-operate with the BITCASH COMPANY & COMMUNITY which is been registered as/represented as FINEX HUB TECHNOLOGIES PVT. LTD.</p>
                  
                <h5> Consumer Data Protection</h5>

                <p style={{textAlign: "justify",color:"white"}}>Bitcash comply as well as supports with DPDP Act (DIGITAL PERSONAL DATA PROTECTION ACT) which was been passed by Parliament on AUG 2023 & came into effect on SEPT 2023.</p>

                <p className="mt-2"><a target="_blank" href="https://www.google.com/url?q=https://www.meity.gov.in/writereaddata/files/Digital%2520Personal%2520Data%2520Protection%2520Act%25202023.pdf&sa=U&sqi=2&ved=2ahUKEwim_qGjp_-IAxUjb_UHHZCMDTkQFnoECCsQAQ&usg=AOvVaw0xszurrVJf7kdeddhtFHL9" style={{textAlign: "justify",color:"white"}}>Click Here To Read The Gazette</a></p>

                <h5> Fees Structure</h5>

                <p style={{textAlign: "justify",color:"white"}}>Bitcash takes flat 0.5% as a regular trading fee excluding GST i.e. 18% of trading fees Apart from this Bitcash just help user in buying/selling for user own assets not for the third parties so TDS won’t be applicable so we don’t take TDS if the procedure of any mischief goes further on user will be whole & sole responsible for the TDS factor (self-buy/sell is subject to no TDS).</p>
                </div>
              </div>
            </div>
            
          </div>

          <div className="login-page-coin__wrapper">
            <img src={Coin3} alt="Coin" className="" />
          </div>

          <div className="login-page-coin__wrapper-2">
            <img src={Coin5} alt="" className="" />
          </div>
        </div>

          {/* <div className="container">
              <div className="footer">
                <div className="d-flex">
                <div className="footer-head"><Link to="privacy-policy" className="text-white">Privacy Policy</Link></div>
                  <div className="footer-head">Terms & Conditions</div>
                </div>
              </div>
          </div> */}

      </div>
    </div>
  );
};
