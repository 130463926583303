import React, { useState, useEffect } from 'react';
import { getCoinDetail } from '../../Api/Api';


const ProfitChart = () => {

  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getCoinDetail();
        setUsers(response.data);
        // console.log(response.data);
      } catch (err) {
        setError(err);
      }
    };

    fetchUsers();
  }, []);

  return (
    <>
      <div className="chart-heading">
        <h4>Asset</h4>
      </div>

      <div className="container">
          <div className="row">
            <div className="col-lg-6" key={users.id} id={users.id}>
              <div className="assets__main-box">
                  <div className="d-flex">
                    <img src={users.image} alt="" width="30px" height="30px" />
                    <div>
                      <p className="assets__logo-heading">{users.name}</p>
                    </div>
                  </div>
                  <p className="assets__logo-price">$ {users.INR_Price}</p>
              </div>
            </div>
          </div>
      </div>
    </>
  );
};

export default ProfitChart;
