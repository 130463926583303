import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header'
import SideNav from '../../components/SideNavigation/SideNav'
import { useParams, Link } from 'react-router-dom';
import { UserDetailsByID } from '../../Api/Api';
import { ToggleSlider }  from "react-toggle-slider";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import BitCashlogo from "../../assets/images/bitcash-logo.png"

export const AllUserDetails = () => {

    const { id } = useParams(); // Extract the user ID from the URL
    const [user, setUser] = useState(null);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = useState(null);
    const [active, setActive] = useState(true)
    const [paymentStatus, setPaymentStatus] = useState('Select');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await UserDetailsByID(id)
        setUser(response.data);
        console.log(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    
      fetchUserDetails();
  }, [id]); // Refetch details when the ID changes

  if (loading) return <div className="">Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!user) return <div>No user found.</div>;


        // Handle change event for radio buttons
        const handleStatusChange = (e) => {
            const value = e.target.value === 'true'; // Convert string to boolean
            setPaymentStatus(value);
        };

        // Handle form submission
        const handleSubmit = async (e) => {
            e.preventDefault();

            const adminToken = sessionStorage.getItem('token');
            const accessToken = JSON.parse(adminToken);
            // console.log(accessToken)

            try {
                const response = await axios.post(`https://backendbitcash.co.in/api/user/changeUserStatus/${id}`, {
                    status: paymentStatus,
                
                }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`, // Include the token in the headers
                    },
                });
                
                if (response.status === 200) {
                    toast.success('Status Updated Successfully!');
                    // setIsSuccess(true);

                    // Redirect to another page after a delay
                    setTimeout(() => {
                        navigate('/all-users');
                    }, 1000); // 1-second delay before redirect

                } else {
                    toast.error('Failed to Update Status.');
                    // setIsSuccess(false);
                }
            } catch (error) {
                console.error('Error updating status:', error);
                toast.error('You Can Update Only Once.');
                // setIsSuccess(false);
            }
        };


  return (
    <div>
        <Header />
        <SideNav />

        <section>
            <div className='user-details-container'>
                <div className='user-details'>
                    <div className='user-details__image'>
                        <img src={user.image ? `https://backendbitcash.co.in${user.image}` : BitCashlogo} alt="user" style={{borderRadius: '50%'}} />
                    </div>
                    <div className='user-details__info'>
                        <h4>Name :- {user.name}</h4>
                        <p>Email :- {user.email}</p>
                        <p>Mobile No. :- {user.mobile}</p>
                        <p>Address :- {user.city}</p>
                        <div className='d-flex justify-content-between'>
                            <p>Adhar Card :- {user.aadhaarNumber}</p>
                            <p><a className='adhar-pan-button' href={`https://backendbitcash.co.in${user.aadhaarCardImage}`} target="_blank" rel="noopener noreferrer">View Aadhaar Card</a></p>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <p>Pan Number :- {user.PAN_Number}</p>
                            <p><a className='adhar-pan-button' href={`https://backendbitcash.co.in${user.PAN_CardImage}`} target="_blank" rel="noopener noreferrer">View Pan Card</a></p>
                        </div>
                        {/* <p>Adhar Card :- {user.aadhaarNumber}</p>
                        <p>Pan Number :- {user.PAN_Number}</p> */}
                        <p>Created At :- {user.createdAt}</p>
                        <p>Status :- {user.isActive ? 'Active' : 'Inactive'}</p>
                        

                        <form onSubmit={handleSubmit} className='d-flex'>
                            <div>
                                <label htmlFor="paymentStatus">Update  Status: </label>
                                <select
                                    id="paymentStatus"
                                    value={paymentStatus}
                                    onChange={handleStatusChange}
                                    className='select-style'
                                >
                                    <option defaultValue disabled>Select</option>
                                    <option value={true}>Active</option>
                                    <option value={false}>Inactive</option>
                                </select>
                            </div>
                            <button style={{marginLeft:"25px",padding:"6px 10px"}} className='transaction-status' type="submit">Update Status</button>
                        </form>

                    </div>
                </div>
            </div>
        </section>

    </div>
  )
}
