import React from 'react'
import Header from '../components/Header/Header'
import SideNav from '../components/SideNavigation/SideNav'
import { DepositTable } from '../components/DepositPage/DepositTable'


export const DepositMainPage = () => {
  return (
    <div>
         <>
            <Header />
            <SideNav />
            <DepositTable/>
        </>
    </div>
  )
}
