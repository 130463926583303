import React from "react";
import MobileImage from "../../assets/images/Login/dizzy-payment-card-with-coins.gif";
import Coin3 from "../../assets/images/Login/coin-3.png";
import Coin5 from "../../assets/images/Login/coin-5.png";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/bitcash-logo.png";
import BannerImage from "../../assets/images/close-up-hand-holding-coin.jpg"
import Crypto from "../../assets/images/crypto.jpg"
import { TiTick } from "react-icons/ti";

export const ReturnRefund = () => {
  
  return (
    <div>
      <div>
        <div>
            <div className="d-flex justify-content-between mt-3">
            <div className="logo-wrapper">
                <img src={Logo} alt=""/>
            </div>
            <div className="d-flex justify-content-evenly">
              
            </div>
            {/* <div className="huelse-drexg">
                <Link to="/login">Login</Link>
            </div> */}
            </div>
        </div>

     


        <div className="container">
          <div className="row">
            <div className="col-lg-12" style={{ display: "flex", alignItems: "center",justifyContent: "center"}}>
              <div className="">
              <div className="login__heding-box">
                  <h1 className="fs-40 sdlkfdjl-2" style={{marginBottom:"20px",marginTop:"40px"}}>Return <span style={{ color: "#e9c147" }}>& Refund Policy</span></h1>
                  <p style={{textAlign: "justify",color:"white"}}>Bitcash can’t refund user debited funds which are been credited to our bank account for trading purpose further on the amount can be tradable & can only be withdrawn by trade.</p>
                  <p style={{textAlign: "justify",color:"white"}}></p>
                  

                <p style={{textAlign: "justify",color:"white"}}>Rather than that if user force us to refund the fund without trading 2% of total funds will be deducted as a service fees excluding GST.</p>
                <p style={{textAlign: "justify",color:"white"}}>Once fund gets credited in users bank account then user is whole & sole owner of the funds after the trade Same goes for assets.</p>

                <h5>Cancellation Of Trade </h5>

                <p style={{textAlign: "justify",color:"white"}}>Cancellation of trade can’t be done as the trade has already been proceeded it is users will weather to trade or not user should take full responsibility weather if the bank account details are wrong, or bank account of user has some issues.</p>
                </div>
              </div>
            </div>
            
          </div>

          <div className="login-page-coin__wrapper">
            <img src={Coin3} alt="Coin" className="" />
          </div>

          <div className="login-page-coin__wrapper-2">
            <img src={Coin5} alt="" className="" />
          </div>
        </div>

          {/* <div className="container">
              <div className="footer">
                <div className="d-flex">
                <div className="footer-head"><Link to="privacy-policy" className="text-white">Privacy Policy</Link></div>
                  <div className="footer-head">Terms & Conditions</div>
                </div>
              </div>
          </div> */}

      </div>
    </div>
  );
};

