import React from 'react'
import AuthUser from '../Api/AuthUser'
import { Navigate } from 'react-router-dom';
import { Login } from '../Pages/Login';


export const ProtectedRoute = ({children}) => {

    const {getToken} = AuthUser();
    if(!getToken()){
        return <Login/>
    }

    return children

}
