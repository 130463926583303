import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/bitcash-logo.png";
import { MdSpaceDashboard } from "react-icons/md";
import { GoPeople, GoGraph } from "react-icons/go";
import { GrTransaction } from "react-icons/gr";
import { TbMoneybag } from "react-icons/tb";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { GiReceiveMoney } from "react-icons/gi";
import {
  FaAngleDown,
  FaAngleUp,
  FaAngleRight,
  FaAngleLeft,
  FaRegMoneyBillAlt,
  FaRegUser,
} from "react-icons/fa";
import { FaMoneyBill1Wave } from "react-icons/fa6";
import { BsCurrencyExchange } from "react-icons/bs";
import { SlEnvolopeLetter } from "react-icons/sl";
import { IoSettingsOutline } from "react-icons/io5";
import { LuLogOut } from "react-icons/lu";
import { Link, NavLink } from "react-router-dom";
import "./sidenav.css";
import AuthUser from "../../Api/AuthUser";

const SideNav = () => {

  const {token, logout} = AuthUser();
  const [report, setReport] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      setReport(false)
    }
  }, [open]);

  const logoutUser =()=>{
    if(token != undefined){
      logout();
    }
  }
  

  return (
    <>
      <div className={open ? "active nav-wrapper" : "nav-wrapper"}>
        <div className="logo-wrapper">
          <img src={Logo} alt=""/>
        </div>

        <div className="mob-view-menu" onClick={() => setOpen(!open)}>
         {open ? <FaAngleLeft/> : <FaAngleRight />}  
        </div>

        <div className="nav-menu">
          <ul>
              <NavLink to="/dashboard">
                <li className="nav-item">
                  {" "}
                    <MdSpaceDashboard className="nav-icon" />{" "}
                    <h5 className={open ? "active" : ""}>Dashboard</h5>
                </li>
              </NavLink>

              <NavLink to="/all-users">
                <li className="nav-item">
                  <FaRegUser className="nav-icon" />{" "}
                  <h5 className={open ? "active" : ""}>All Users</h5>
                </li>
              </NavLink>

              <NavLink to="/bank">
                <li className="nav-item">
                  <FaMoneyBill1Wave className="nav-icon" />{" "}
                  <h5 className={open ? "active" : ""}>Bank</h5>
                </li>
              </NavLink>

              <NavLink to="/no-of-buyers">
                <li className="nav-item">
                  <GoPeople className="nav-icon" />{" "}
                  <h5 className={open ? "active" : ""}>Buyers</h5>{" "}
                </li>
              </NavLink>
            
              <NavLink to="/no-of-seller">
                <li className="nav-item">
                  <GoPeople className="nav-icon" />{" "}
                  <h5 className={open ? "active" : ""}>Seller</h5>
                </li>
              </NavLink>

              <NavLink to="/coin-deposit">
                <li className="nav-item">
                  <FaRegMoneyBillAlt className="nav-icon" />{" "}
                  <h5 className={open ? "active" : ""}>Coin Deposit</h5>
                </li>
              </NavLink>
            
              <NavLink to="/deposit">
                <li className="nav-item">
                  <TbMoneybag className="nav-icon" />
                  <h5 className={open ? "active" : ""}>Money Deposit</h5>
                </li>
              </NavLink>
            
              <NavLink to="/withdraw">
                <li className="nav-item">
                  <FaMoneyBillTransfer className="nav-icon" />
                  <h5 className={open ? "active" : ""}>Money Withdraw</h5>
                </li>
              </NavLink>

            {/* <NavLink to="#">
            <li className="nav-item">
              <GiReceiveMoney className="nav-icon" />
              <h5 className={open ? "active" : ""}>Transfer Share</h5>
            </li>
            </NavLink> */}

            {/* <NavLink to="/transaction">
            <li className="nav-item">
              <GrTransaction className="nav-icon" />
              <h5 className={open ? "active" : ""}>Transaction</h5>
            </li>
            </NavLink> */}

            {/* <li className="nav-item">
              <GoGraph className="nav-icon" />
              <h5 className={open ? "active" : ""}> Reports </h5>
              {report ? (
                <FaAngleUp
                  className={open ? "active down-icon" : "down-icon"}
                  onClick={() => setReport(!report)}
                />
              ) : (
                <FaAngleDown
                  className={open ? "active down-icon" : "down-icon"}
                  onClick={() => setReport(!report)}
                />
              )}
            </li> */}

            {/* dropdown menu  */}
            {/* {report && (
              <>
                <li className="nav-item-dropdown">Option 1</li>
                <li className="nav-item-dropdown">Option 2</li>
              </>
            )} */}

            {/* <li className="nav-item">
              <SlEnvolopeLetter className="nav-icon" />{" "}
              <h5 className={open ? "active" : ""}>Message</h5>
            </li> */}

            {/* <NavLink to="/exchange-platform-price">
              <li className="nav-item">
                <BsCurrencyExchange className="nav-icon" />
                <h5 className={open ? "active" : ""}>Exchange Platform</h5>
              </li>
            </NavLink> */}

              <NavLink to="/platform-price">
                <li className="nav-item">
                  <FaRegMoneyBillAlt className="nav-icon" />{" "}
                  <h5 className={open ? "active" : ""}>Platform Price</h5>
                </li>
              </NavLink>

              <NavLink to="/coin-value">
                <li className="nav-item">
                  <FaRegMoneyBillAlt className="nav-icon" />{" "}
                  <h5 className={open ? "active" : ""}>Coin Value</h5>
                </li>
              </NavLink>

            {/* <NavLink to="/setting">
              <li className="nav-item">
                <IoSettingsOutline className="nav-icon" />
                <h5 className={open ? "active" : ""}>Setting</h5>
              </li>
            </NavLink> */}

            <li className="nav-item">
              <LuLogOut className="nav-icon"/>
              <h5 className={open ? "active" : ""} onClick={logoutUser}>Logout</h5>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideNav;