import React, { useState, useEffect } from 'react';
import { MdCurrencyExchange } from "react-icons/md";
import { getTotalDepositDetail } from '../../Api/Api';


export const TotalSellCoins = () => {

    const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getTotalDepositDetail();
        setUsers(response.data);
        // console.log(response.data);
      } catch (err) {
        setError(err);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div>
        <div className="card-wrapper">
        <div className="card-icon-wrap">
          <div className="card-icon-body">
            <MdCurrencyExchange className="card-icon" />
          </div>
        </div>
        <div className="card-text-wrap">
          <h4>Total Sell Coins</h4>
          <h2>{users.totalSellCoins}</h2>
        </div>
      </div>
    </div>
  )
}
