import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import '../Transaction/transactionpage.css'
import { Link } from 'react-router-dom';
import { UsersDepositRequest } from '../../Api/Api';
import BitCashlogo from "../../assets/images/bitcash-logo.png"



export const DepositTable = () => {

  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

      useEffect(() => {
        const fetchUsers = async () => {
          try {
            const response = await UsersDepositRequest();
            setUsers(response.data);
            console.log(response.data);
          } catch (err) {
            setError(err);
          }
        };

        fetchUsers();
      }, []);

  return (
    <section>
        <Table bordered hover responsive>
          <thead style={{backgroundColor: '#151515'}}>
            <tr className='table-head'>
              <th>Profile</th>
              <th>Name</th>
              <th>Amount</th>
              {/* <th>Bank ID</th> */}
              <th>Date & Time</th>
              <th>Transaction</th>
              <th>Payment Mode</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody >
          {error ? (
            <tr>
              <td colSpan="8" style={{ textAlign: 'center', color: 'red' }}>
                Error: {error.message || 'Failed to load data'}
              </td>
            </tr>
          ) : users.length === 0 ? (
            <tr>
              <td colSpan="8" className='no-data-found'>
                No Data Found
              </td>
            </tr>
          ) : (
          users.map((item)=>(
            <tr className='table-body' key={item.id} id={item.id}>
              <td><img src={item.userId && item.userId.image ? `https://backendbitcash.co.in${item.userId.image}` : BitCashlogo} style={{borderRadius: '50%'}}  height={40} width={40}></img></td>
              <td>{item.userId && item.userId.name ? item.userId.name : 'Unknown User'}</td>
              <td>₹ {item.INR_Amount}</td>
              {/* <td>{item.bankId.accountNumber}</td> */}
              <td>{item.createdAt}</td>
              <td>{item.type}</td>
              <td>{item.transactionType}</td>
              <td>{item.status}</td>
              <td><Link to={`/deposit-money-details/${item.id}`}><span className='transaction-status'>View</span></Link></td>
            </tr>
            )))}
          </tbody>
    </Table>
    </section>
  )
}
