import React , { useState, useEffect }from 'react'
import Header from '../../components/Header/Header'
import SideNav from '../../components/SideNavigation/SideNav'
import { getCoinDetail } from '../../Api/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from 'react-router-dom';
import { updateField } from '../../Api/Api';

import { Link } from "react-router-dom";

export const CoinValueFile = () => {

    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);;

    useEffect(() => {
        const fetchUsers = async () => {
        try {
            const response = await getCoinDetail();
            setUsers(response.data);
            console.log(response.data);
        } catch (err) {
            setError(err);
        }
        };

        fetchUsers();
    }, []);

    
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleUpdateClick = async () => {
        if (!inputValue) {
            toast.error('Input cannot be empty!');
            return;
        }

        setLoading(true);

        try {
            
            const response = await updateField(inputValue);

            toast.success('Value updated successfully!');
            // You can handle additional success actions here (e.g., updating local state)

            setTimeout(() => {
                window.location.reload();
            }, 1500); // 1-second delay before refreshing the page

        } catch (error) {
            console.error('Error updating value:', error);
            toast.error('Failed to update value. Please try again.');
        } finally {
            setLoading(false);
        }
    };

  

  return (
    <div>
       <Header />
       <SideNav /> 
        
        <section>
            <div className='recent-transaction-wrapper mt-140'>
                <div className='container-coinvalue'>
                    <div className='coinvalue-heading'>
                        <h4>Current Coin Value</h4>
                    </div>
                    <div className='coinvalue-content'>
                        <div className="coinvalue__main-box" key={users.id} id={users.id}>
                            <div className="d-flex">
                                <img src={users.image} alt="" width="30px" height="30px"/>
                                    <div>
                                        <p className="assets__logo-heading">{users.name}</p>
                                    </div>
                            </div>
                            <p className="assets__logo-price">$ {users.INR_Price}</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className='recent-transaction-wrapper'>
                <div className='container-coinvalue'>
                    <div className='coinvalue-heading'>
                        <h4>Update Coin Value</h4>
                    </div>
                    <div className='coinvalue-content'>
                        <div className="coinvalue__main-box-2">
                            <div className="d-flex">
                                <img src="https://coin-images.coingecko.com/coins/images/325/large/Tether.png?1696501661" alt="" width="30px" height="30px"/>
                                    <div>
                                        <p className="assets__logo-heading">USDT</p>
                                    </div>
                            </div>
                            {/* <p classname="assets__logo-price">$ 85.5</p> */}
                            <input type="text" className='coinvalue__input' value={inputValue} onChange={handleInputChange} placeholder='Enter Coin Value'/>
                            <button className="transaction-status" onClick={handleUpdateClick} disabled={loading} type="submit" style={{marginLeft:"25px",padding:"6px 10px",marginTop:"20px"}}>{loading ? 'Updating...' : 'Update'}</button>
                        </div>
                    </div>
                </div>
            </div>



            <div className='history-button-postion'>
            <button className='transaction-status' style={{padding:"6px 10px",marginRight:"20px",marginTop:"5px"}}>
              <Link to="/coin-price-history" style={{color:"#000"}}>See all History</Link>
            </button>
          </div>





        </section>
        
        
    </div>
  )
}
