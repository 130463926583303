import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import '../Transaction/transactionpage.css'
import { Link } from 'react-router-dom';
import { totalUserList } from '../../Api/Api';
import BitCashlogo from "../../assets/images/bitcash-logo.png"


export const AllUsersData = () => {

    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await totalUserList();
        setUsers(response.data);
        console.log(response.data);
      } catch (err) {
        setError(err);
      }
    };

    fetchUsers();
  }, []);

 

  return (
    <section>
    <Table bordered hover responsive>
      <thead style={{backgroundColor: '#151515'}}>
        <tr className='table-head'>
          <th>Profile </th>
          <th>User Name</th>
          <th>Email ID</th>
          <th>Mobile No.</th>
          <th>Pan Number</th>
          <th>City</th>
          <th>Status</th>
          <th>Joined Date</th>
          {/* <th>Status</th> */}
        </tr>
      </thead>
      <tbody >
      {error ? (
          <tr>
            <td colSpan="8" style={{ textAlign: 'center', color: 'red' }}>
              Error: {error.message || 'Failed to load data'}
            </td>
          </tr>
        ) : users.length === 0 ? (
          <tr>
            <td colSpan="8" className='no-data-found'>
              No Data Found
            </td>
          </tr>
        ) : (
      users.map((item) => (
        <tr className='table-body' key={item.id} id={item.id}>
          <td><img src={item.image ? `https://backendbitcash.co.in${item.image}` : BitCashlogo} alt={item.name} width={50} height={50} style={{borderRadius: '50%'}}/></td>
          <td><strong><Link to={`/all-users-details/${item.id}`} className='text-white'>{item.name}</Link></strong></td>
          {/* <td>{item.name}</td> */}
          <td style={{textTransform: 'lowercase'}}>{item.email}</td>
          <td>{item.mobile}</td>
          <td>{item.PAN_Number}</td>
          <td>{item.city}</td>
          <td>{item.isActive ? 'Active' : 'Inactive'}</td>
          <td>{item.createdAt}</td>
          {/* <td><span className='transaction-status'>{item.status}</span></td> */}
        </tr>
        )))}
      </tbody>
</Table>
</section>
  )
}

















