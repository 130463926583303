import React from 'react'
import Header from '../components/Header/Header'
import SideNav from '../components/SideNavigation/SideNav'
import { SettingScreen } from '../components/Setting/SettingScreen'

export const Setting = () => {
  return (
    <>
        <Header />
        <SideNav />
        <SettingScreen/>
    </>
  )
}
