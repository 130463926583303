import axios from 'axios';

//  Base URL for the API
const API_BASE_URL = 'https://backendbitcash.co.in/api/';

const tokenString = sessionStorage.getItem("token");
const accessToken = JSON.parse(tokenString);

// Create an Axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    // You can add more headers here, like authorization tokens
    Authorization: `Bearer ${accessToken}`
  }
});

// 1. Get Users List Who have resgistred on the platform
export const totalUserList = async () => {
  try {
    const response = await api.get('admin/totalMembers');
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};

// 2. Get Users Details by id Who have resgistred on the platform
export const UserDetailsByID = async (id) => {
  try {
    const response = await api.get(`user/getUserProfileById/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};

// 3. Get Coin details 
export const getCoinDetail = async () => {
  try {
    const response = await api.get('coin/getCoinDetail');
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};

// 3. Get Coin details 
export const getTotalDepositDetail = async () => {
  try {
    const response = await api.get('admin/totalTransactions');
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};

// 4. Get All Users withdrawal request
export const UsersWithdrawalRequest = async () => {
  try {
    const response = await api.get('/depositWithdraw/getWithdrawRequestList');
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};

// 5.2. Get Withdraw users Details By ID
export const GetWithdrawDeatilsUser = async (id) => {
  try {
    const response = await api.get(`/depositWithdraw/getDetails/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};

// 5.1. Get All Users Deposit request
export const UsersDepositRequest = async () => {
  try {
    const response = await api.get('/depositWithdraw/getDepositRequestList');
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};

// 5.2. Get Deposit users Details By ID
export const GetDepositDeatilsUser = async (id) => {
  try {
    const response = await api.get(`/depositWithdraw/getDetails/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};

// 6. Get admin bank submit 
export const GetUserBankName = async () => {
  try {
    const response = await api.get('/bank/getAdminBankAccounts');
    
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};

// 6. Get Buyer List 
export const GetBuyerList = async () => {
  try {
    const response = await api.get('/coinBuySell/getBuyersList');
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};


// 7. Get Buyer Details By ID
export const GetBuyerDeatils = async (id) => {
  try {
    const response = await api.get(`/coinBuySell/getDetails/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};


// 8. Get Seller List 
export const GetSellerList = async () => {
  try {
    const response = await api.get('/coinBuySell/getSellersList');
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};


// 9. Get Seller Details By ID
export const GetSellerDeatils = async (id) => {
  try {
    const response = await api.get(`/coinBuySell/getDetails/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};


// 10. Get Coin Deposit
export const GetCoinDeposit = async () => {
  try {
    const response = await api.get('/coinBuySell/getDepositList');
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};


// 11. Get Coin Deposit By ID
export const GetCoinDepositDeatils = async (id) => {
  try {
    const response = await api.get(`/coinBuySell/getDetails/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};


// 12. Get Coin Deposit
export const GetPlatformPrice = async () => {
  try {
    const response = await api.get('/platformPrice/getPlatformPrice');
    return response.data;
  } catch (error) {
    console.error('Error fetching data', error);
    throw error;
  }
};


// Patch Method
// update Coin Value  by patch methode
export const updateField = async (updatedValue) => {
  try {
      const response = await api.patch(`coin/updateCoinPrice/667bf9578a17ed623addef80`, 
      {
        INR_Price: updatedValue // Replace 'fieldName' with the actual field name in your API
      });
      return response.data;
  } catch (error) {
      throw error;
  }
};




















// Example function to post data to an endpoint
export const PostDataForBuywer = async (data) => {
  try {
    const response = await api.post('/coinBuySell/getDetails/${id}', data);
    return response.data;
  } catch (error) {
    console.error('Error posting data', error);
    throw error;
  }
};





// Example function to post data to an endpoint
export const postData = async (data) => {
  try {
    const response = await api.post('/data-endpoint', data);
    return response.data;
  } catch (error) {
    console.error('Error posting data', error);
    throw error;
  }
};

// Add more API functions as needed


export const ChangePassword = async (oldPassword,newPassword,confirmPassword) => {
  try {
    const response = await api.patch('/admin/changePassword', {
      oldPassword,
      newPassword,
      confirmPassword
    });
    return response.data;
  } catch (error) {
    console.error('Error updating password:', error);
    throw error;
  }
};


