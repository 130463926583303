import React, { useState, useEffect } from 'react';
import { BiMoneyWithdraw } from "react-icons/bi";
import { getTotalDepositDetail } from '../../Api/Api';


const Withdraw = () => {

  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getTotalDepositDetail();
        setUsers(response.data);
        // console.log(response.data);
      } catch (err) {
        setError(err);
      }
    };

    fetchUsers();
  }, []);

  return (
    <>
      <div className="card-wrapper">
        <div className="card-icon-wrap">
            <div className="card-icon-body">
               <BiMoneyWithdraw className='card-icon'/>
            </div>
        </div>
        <div className='card-text-wrap'>
            <h4>Total Withdraw</h4>
            <h2>$ {users.totalWithdrawMoney}</h2>
        </div>
      </div>
      
    </>
  )
}

export default Withdraw
