import React from 'react'
import Header from '../components/Header/Header'
import SideNav from '../components/SideNavigation/SideNav'
import { AllUsersData } from '../components/AllUsersPage/AllUsersData'


export const AllUsers = () => {
  return (
    <div>
        <Header />
        <SideNav />
        <AllUsersData/>
    </div>
  )
}
