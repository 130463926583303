import React, { useEffect, useState }from 'react'
import Table from 'react-bootstrap/Table';
import '../Transaction/transactionpage.css'
import FromImageName from "../../assets/images/user-1.png";
import ToImageName from "../../assets/images/user-2.png";
import DollarLogo from "../../assets/images/b-dollar-logo.png";
import EthereumLogo from "../../assets/images/t-icon.png";
import MoneroLogo from "../../assets/images/d-icon.png";
import BitCashlogo from "../../assets/images/bitcash-logo.png"

import { GetSellerList } from '../../Api/Api';
import { Link } from 'react-router-dom';


export const SellerList = () => {


  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await GetSellerList();
        setUsers(response.data);
        console.log(response.data);
      } catch (err) {
        setError(err);
      }
    };

    fetchUsers();
  }, []);

  return (
    <section>
        <Table bordered hover responsive>
          <thead style={{backgroundColor: '#151515'}}>
            <tr className='table-head'>
              <th>Seller Profile</th>
              <th>Seller Name</th>
              <th>Mobile No.</th>
              <th>Coins</th>
              <th>Date & Time</th>
              <th>Payment Mode</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody >
          {error ? (
            <tr>
              <td colSpan="8" style={{ textAlign: 'center', color: 'red' }}>
                Error: {error.message || 'Failed to load data'}
              </td>
            </tr>
          ) : users.length === 0 ? (
            <tr>
              <td colSpan="8" className='no-data-found'>
                No Data Found
              </td>
            </tr>
          ) : (
          users.map((item)=>(
            <tr className='table-body' key={item.id} id={item.id}>
              <td><img src={item.userId && item.userId.image ? `https://backendbitcash.co.in${item.userId.image}` : BitCashlogo} style={{borderRadius: '50%'}}  height={40} width={40}></img></td>
              <td>{item.userId && item.userId.name}</td>
              <td>{item.userId && item.userId.mobile}</td>
              <td>{item.quantityUSDT} USDT</td>
              <td>{item.createdAt}</td>
              <td>{item.paymentOption}</td>
              <td>{item.status}</td>
              <td><Link to={`/seller-details/${item.id}`}><span className='transaction-status'>View</span></Link></td>
            </tr>
          )))}
          </tbody>
    </Table>
    </section>
  )
}
