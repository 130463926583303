import React from 'react'
import { CoinValueFile } from '../components/CoinValuefol/CoinValueFile'

export const CoinValue = () => {
  return (
    <div>
        <CoinValueFile/>
    </div>
  )
}
