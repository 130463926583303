import React from "react";
import { useState } from "react";
import Deposit from "../components/Dashboard/Deposit";
import Withdraw from "../components/Dashboard/Withdraw";
import Exchange from "../components/Dashboard/Exchange";
import Assets from "../components/Dashboard/Assets";
import TotalMembers from "../components/Dashboard/TotalMembers";
import RecentTransaction from "../components/Dashboard/RecentTransaction";
import MarketTrend from "../components/Dashboard/MarketTrend";
import { TbMoneybag } from "react-icons/tb";
import DollarUp from "../assets/images/dollar-up.png";
import ProfitChart from "../components/Dashboard/ProfitChart";
import { LuBitcoin } from "react-icons/lu";
import Header from "../components/Header/Header";
import SideNav from "../components/SideNavigation/SideNav";
import "../components/Dashboard/responsive.css";
import { TotalBuyCoins } from "../components/Dashboard/TotalBuyCoins";
import { TotalSellCoins } from "../components/Dashboard/TotalSellCoins";

const DashBoard = () => {
  return (
    <>
      <Header />
      <SideNav />
      <div className="dashboard-wrapper">
        <div className="cards-section-wrapper">
          <Deposit />
          <Withdraw />
          {/* <Exchange /> */}
          <TotalBuyCoins/>
          <TotalSellCoins/>
          <TotalMembers />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                {/* <div className="col-lg-12">
                  <div className="graph-transaction-wrapper">
                    <div className="graph-wrapper">
                      <div className="graph-heading-section">
                        <div className="graph-heading">
                          <h4>Market Overview</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-lg-6">
                  <div className="main-chart-wrapper">
                    <MarketTrend />
                  </div>
                </div> */}
                <div className="col-lg-12">
                  <div className="profit-wrap">
                    <RecentTransaction />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="recent-transaction-wrapper">
                <ProfitChart />
              </div>
            </div>
          </div>
        </div>

        {/* <Assets /> */}
      </div>
    </>
  );
};

export default DashBoard;
