import React from 'react'
import { PlatformPriceFile } from '../components/PlatformPricefol/PlatformPriceFile'

export const PlatformPrice = () => {
  return (
    <div>
        <PlatformPriceFile/>
    </div>
  )
}
