import React from 'react'
import Header from '../components/Header/Header'
import SideNav from '../components/SideNavigation/SideNav'
import { CoinDepositList } from '../components/CoinDeposit/CoinDepositList'

export const CoinDeposit = () => {
  return (
    <div>
        <Header />
        <SideNav />
        <CoinDepositList/>
    </div>
  )
}
