import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import SideNav from "../../components/SideNavigation/SideNav";
import Table from "react-bootstrap/Table";
import axios from "axios";

export const CoinValueHistory = () => {
  const adminToken = sessionStorage.getItem("token");
  const accessToken = JSON.parse(adminToken);

  const [coinHistory, setCoinHistory] = useState([]);
  const [error, setError] = useState(null);

  const fetchHistory = async () => {
    try {
      const response = await axios.get(
        "https://backendbitcash.co.in/api/coinHistory/getAllCoinHistory?page=1&limit=100",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log(response);

      setCoinHistory(response.data.data);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <div>
      <Header />
      <SideNav />

      <section>
        <Table bordered hover responsive>
          <thead style={{ backgroundColor: "#151515" }}>
            <tr className="table-head">
              <th>Sr. No. </th>
              <th>Old Price </th>
              <th>New Price </th>
              <th>Updated Date</th>
            </tr>
          </thead>
          <tbody>
            {coinHistory.map((item, index) => (
              <tr className="table-body" key={item.id} id={item.id}>
                <td>{index + 1}</td>
                <td>{item.oldINR_Price}</td>
                <td>{item.newINR_Price}</td>
                <td>{item.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </section>
    </div>
  );
};
