import React from 'react'
import Header from '../components/Header/Header'
import SideNav from '../components/SideNavigation/SideNav'
import { AddBankPage } from '../components/AddBank/AddBankPage'


export const BankListPage = () => {
  return (
    <div>
        <Header />
        <SideNav />
        <AddBankPage/>
    </div>
  )
}
