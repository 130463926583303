import React from 'react'
import Header from '../components/Header/Header'
import SideNav from '../components/SideNavigation/SideNav'
import { TransactionPage } from '../components/Transaction/TransactionPage'

export const Transaction = () => {
  return (
    <div>
        <>
            <Header />
            <SideNav />          
            <TransactionPage/>
        </>
    </div>
  )
}
