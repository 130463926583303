import React, { useEffect, useState } from "react";
import { GetUserBankName } from "../../Api/Api";
import Table from "react-bootstrap/Table";
import { FaRegEdit } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";

import { Dropdown, DropdownButton } from "react-bootstrap";

export const AddBankPage = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  const [bankNames, setBankNames] = useState([]);

  const [formData, setFormData] = useState({
    bankNameId: "",
    bankName: "",
    accountnumber: "",
    ifsccode: "",
    accountholdername: "",
    // branchname: "",
    mobileNumber: "",
  });

  const adminToken = sessionStorage.getItem("token");
  const accessToken = JSON.parse(adminToken);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleBankSelect = (eventKey) => {
    const selectedBank = bankNames.find((bank) => bank.id === eventKey);
    setFormData({
      ...formData,
      bankNameId: selectedBank.id,
      bankName: selectedBank.bankName,
    });
  };

  const deleteBank = async (bankId) => {
    try {
      const bank = await axios.delete(
        // "https://backendbitcash.co.in/api/bank/removeBank/66d6c5bf2e1688850609c8b8",
        `https://backendbitcash.co.in/api/bank/removeBank/${bankId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      fetchUsers();

      // console.log("Data deleted successfully");
      toast.success("Bank deleted successfully");
    } catch (error) {      
      console.error("There was an error deleting the data:", error);
      toast.error("Failed to delete bank");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data
    if (
      !formData.bankNameId ||
      !formData.accountnumber ||
      !formData.ifsccode ||
      !formData.accountholdername ||
      !formData.mobileNumber
    ) {
      console.error("Please fill all required fields.");
      return;
    }

    try {
      const response = await axios.post(
        "https://backendbitcash.co.in/api/bank/addNewBankAccount",
        {
          bankNameId: formData.bankNameId,
          accountNumber: formData.accountnumber,
          ifscCode: formData.ifsccode,
          accountHolderName: formData.accountholdername,
          mobileNumber: formData.mobileNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Data successfully added:", response.data);
      toast.success("Bank added successfully");

      setFormData({
        bankNameId: "",
        bankName: "",
        accountnumber: "",
        ifsccode: "",
        accountholdername: "",
        // branchname: "",
        mobileNumber: "",
      });

      fetchUsers();
    } catch (error) {
      if (error.response.data) {
        toast.error("Invalid IFSC code provided!");
      }
      // toast.error("There was an error adding the data!");
      console.error("There was an error adding the data:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await GetUserBankName();
      setUsers(response.data);
    } catch (err) {
      setError(err);
    }
  };


  const fetchBankNames = async () => {
    try {
      const adminToken = sessionStorage.getItem("token");
      const accessToken = JSON.parse(adminToken);
      const response = await axios.get(
        "https://backendbitcash.co.in/api/bank/getAllBankNames?page=1&limit=50",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setBankNames(response.data.data);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchBankNames();
  }, []);


  return (
    <section>
      <div className="container">
        <div className="row box-for-bank">
          <h5 className="text-center">Add Your Bank</h5>

          <div className="col-lg-12">
            <form onSubmit={handleSubmit} className="bank-form">
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    <label>Bank Name :- </label>
                    <br></br>

                    <DropdownButton
                      id="dropdown-basic-button"
                      title={formData.bankName || "Select Bank"} // Display selected bank name or default text
                      onSelect={handleBankSelect} // Update handler
                    >
                      {bankNames.map((bank) => (
                        <Dropdown.Item key={bank.id} eventKey={bank.id}>
                          <img
                            src={bank.imageUrl}
                            alt={bank.bankName}
                            width="25px"
                            height="25px"
                            style={{ marginRight: "15px" }}
                          />
                          {bank.bankName}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>

                  <div>
                    <label>Account Holder Name :- </label>
                    <br></br>
                    <input
                      type="text"
                      name="accountholdername"
                      value={formData.accountholdername}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <label>IFSC Code :- </label>
                    <br></br>
                    <input
                      type="text"
                      name="ifsccode"
                      value={formData.ifsccode}
                      onChange={handleChange}
                    />
                  </div>
                  
                </div>
                <div className="col-lg-6">
                  
                  {/* <div>
                    <label>Branch Name :- </label>
                    <br></br>
                    <input
                      type="text"
                      name="branchname"
                      value={formData.branchname}
                      onChange={handleChange}
                    />
                  </div> */}

                  <div>
                    <label>Mobile Number :- </label>
                    <br></br>
                    <input
                      type="text"
                      name="mobileNumber"
                      pattern="[7-9]{1}[0-9]{9}"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <label>Account Number :- </label>
                    <br></br>
                    <input
                      type="number"
                      name="accountnumber"
                      value={formData.accountnumber}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              {/* <div className="text-center d-flex justify-content-center">
                <div className="bank-btn">Submit</div>
              </div> */}

              <div className="text-center d-flex justify-content-center">
                <button type="submit" className="bank-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div>
          <Table bordered hover responsive>
            <thead style={{ backgroundColor: "#151515" }}>
              <tr className="table-head">
                <th>Image</th>
                <th>Bank Name</th>
                <th>Account Holder Name</th>
                <th>Account Number</th>
                {/* <th>IFSC Code</th> */}
                {/* <th>Status</th> */}
                {/* <th>Date & Time</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map((item) => (
                <tr className="table-body" key={item.id} id={item.id}>
                  <td>
                    <img
                      src={item.imageUrl}
                      alt=""
                      width="30px"
                      height="30px"
                    ></img>
                  </td>
                  <td>{item.bankName}</td>
                  <td>{item.accountHolderName}</td>
                  <td>{item.accountNumber}</td>
                  {/* <td>{item.ifscCode}</td> */}
                  <td>
                    <button className="delete-button" onClick={() => deleteBank(item.id)}><MdDeleteForever className="fs-20" /></button>
                  </td>
                  {/* <td>{item.isActive === true ? "Active" : "InActive"}</td> */}
                  {/* <td>{item.createdAt}</td> */}
                  {/* <td>
                    <FaRegEdit />
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </section>
  );
};

// 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

// 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   ----------> Token
