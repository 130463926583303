import React, { useEffect, useState }from 'react'
import Table from 'react-bootstrap/Table';
import '../Transaction/transactionpage.css'
import { GetCoinDeposit } from '../../Api/Api';
import { Link } from 'react-router-dom';
import BitCashlogo from "../../assets/images/bitcash-logo.png"

export const CoinDepositList = () => {

    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await GetCoinDeposit();
        setUsers(response.data);
        // console.log(response.data);
      } catch (err) {
        setError(err);
      }
    };

    fetchUsers();
  }, []);

  

  return (
    <section>
    <Table bordered hover responsive>
      <thead style={{backgroundColor: '#151515'}}>
        <tr className='table-head'>
          <th>Profile</th>
          <th>Name</th>
          <th>Mobile No.</th>
          <th>Coins</th>
          <th>Date & Time</th>
          <th>Network</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody >
      {error ? (
            <tr>
              <td colSpan="8" style={{ textAlign: 'center', color: 'red' }}>
                Error: {error.message || 'Failed to load data'}
              </td>
            </tr>
          ) : users.length === 0 ? (
            <tr>
              <td colSpan="8" className='no-data-found'>
                No Data Found
              </td>
            </tr>
          ) : (
      users.map((item)=>(
        <tr className='table-body' key={item.id} id={item.id}>
          <td><img src={item.userId && item.userId.image ? `https://backendbitcash.co.in${item.userId.image}` : BitCashlogo} style={{borderRadius: '50%'}}  height={40} width={40}></img></td>
          <td>{item.userId && item.userId.name}</td>
          <td>{item.userId && item.userId.mobile}</td>
          <td>{item.quantityUSDT} USDT</td>
          <td>{item.createdAt}</td>
          <td>{item.isDeposit.network}</td>
          <td>{item.status}</td>
          <td><Link to={`/coin-deposit-details/${item.id}`}><span className='transaction-status'>View</span></Link></td>
        </tr>
        )))}
      </tbody>
</Table>
</section>
  )
}
