import React, { useState } from "react";
import MobileImage from "../assets/images/Login/dizzy-payment-card-with-coins.gif";
import Coin3 from "../assets/images/Login/coin-3.png";
import Coin5 from "../assets/images/Login/coin-5.png";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { Link } from "react-router-dom";

export const ForgotPassword = () => {
  const [userData, setUserData] = useState({
    email: "",
  });

  const changeInputHandler = (e) => {
    setUserData((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  return (
    <div>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="forgot-password__main-container">
                <div className="login__heding-box">
                  {/* <h1>Forgot Password</h1> */}
                  <h2>Forgot Password !</h2>
                  <p>Enter Your Email Address</p>
                </div>
                <div className="login__form-container">
                  <form action="" className="login__form">
                    <label htmlFor="Email">Email</label>
                    <input
                      type="email"
                      placeholder="youremail@gmail.com"
                      name="email"
                      value={userData.email}
                      onChange={changeInputHandler}
                    />

                    <button type="submit" className="login__form-button mt-4">
                      Send OTP
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login__form-image">
                <img src={MobileImage} alt="" width="80%" />
              </div>
            </div>
          </div>

          <div className="login-page-coin__wrapper">
            <img src={Coin3} alt="" className="" />
          </div>

          <div className="login-page-coin__wrapper-2">
            <img src={Coin5} alt="" className="" />
          </div>
        </div>
      </div>
    </div>
  );
};
