import React, { useState } from "react";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChangePassword } from "../../Api/Api";

export const SettingScreen = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, ConfirmsetPasswordVisible] = useState(false);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };
  const ConfirmtogglePasswordVisibility = () => {
    ConfirmsetPasswordVisible(!confirmPasswordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (newPassword !== confirmPassword) {
        toast.error('New Password and Confirm Password do not match');
        return;
    }

    try {
     
        const response = await ChangePassword(oldPassword, newPassword, confirmPassword);
        // console.log(response.data);

        if (response.status === 200) {
            toast.success('Password updated successfully');
            navigate('/dashboard'); // Redirect if needed
        } else {
            toast.error('Failed to update password');
        }
    } catch (error) {
        console.error('Error updating password:', error);
        toast.error('Error updating password. Please try again.');
    }
};

  return (
    <div>
      <div className="setting__main-container">
        <div className="setting__container">
          <h1 className="setting__heading">Change Password</h1>
          <p className="setting__text">Update your Password and Enjoy.</p>
          <form className="setting__form" onSubmit={handleSubmit}>
            <label htmlFor="">Old Password</label>
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="*******"
              id="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="toggle-button"
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? <BsEyeSlash /> : <BsEye />}
            </button>
            <label htmlFor="">New Password</label>
            <input
              type={newPasswordVisible ? "text" : "password"}
              placeholder="*******"
              id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="toggle-button"
              onClick={toggleNewPasswordVisibility}
            >
              {newPasswordVisible ? <BsEyeSlash /> : <BsEye />}
            </button>
            <label htmlFor="">Confirm Password</label>
            <input
              type={confirmPasswordVisible ? "text" : "password"}
              placeholder="*******"
              id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="toggle-button"
              onClick={ConfirmtogglePasswordVisibility}
            >
              {confirmPasswordVisible ? <BsEyeSlash /> : <BsEye />}
            </button>
            <button type="submit" className="login__form-button mt-5">
              {" "}
              Save{" "}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
