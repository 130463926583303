import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AuthUser() {

    const navigate = useNavigate();

    const getToken = () => {
        const tokenString = sessionStorage.getItem("token");
        const userToken = JSON.parse(tokenString);
        return userToken;
    }

    const getUser = () => {
        const userString = sessionStorage.getItem("user");
        const user_detail = JSON.parse(userString);
        return user_detail;
    }

    const [token,setToken] = useState(getToken());
    const [user,setUser] = useState(getUser());

    const saveToken = (user, token) => {
        setToken(token);
        sessionStorage.setItem("token",JSON.stringify(token));
        sessionStorage.setItem("user",JSON.stringify(user));
        
        setUser(user);
        navigate("/dashboard"); 

        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    const logout = () => {
        sessionStorage.clear();
        setToken(null);
        setUser(null);
        navigate("/login");
    }

    const http = axios.create({
        baseURL: "https://backendbitcash.co.in/api/",
        headers: {
            "Content-type": "application/json",
        },
    });
    return{
        setToken:saveToken,
        token,
        user,
        getToken,
        http,
        logout
    }
}