import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import SideNav from "../../components/SideNavigation/SideNav";
import Table from "react-bootstrap/Table";
import axios from "axios";

export const PlatformPriceHistory = () => {
  const adminToken = sessionStorage.getItem("token");
  const accessToken = JSON.parse(adminToken);

  const [platformPriceHistory, setPlatformPriceHistory] = useState([]);
  const [error, setError] = useState(null);

  const fetchHistory = async () => {
    try {
      const response = await axios.get(
        "https://backendbitcash.co.in/api/platformPriceHistory/getAllHistory?page=1&limit=100",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log(response);

      setPlatformPriceHistory(response.data.data);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <div>
      <Header />
      <SideNav />

      <section>
        <Table bordered hover responsive>
          <thead style={{ backgroundColor: "#151515" }}>
            <tr className="table-head">
              <th>Cash Price </th>
              <th>CDM Price </th>
              <th>Base Price </th>
              <th>Old Selling Price</th>
              <th>New Selling Price</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {platformPriceHistory.map((item) => (
              <tr className="table-body" key={item.id} id={item.id}>
                <td>
                  {item.oldBuyingPrice.cash} {"->"} {item.newBuyingPrice.cash}
                </td>
                <td>
                  {item.oldBuyingPrice.CDM} {"->"} {item.newBuyingPrice.CDM}
                </td>
                <td>
                  {item.oldBasePrice} {"->"} {item.newBasePrice}
                </td>

                {/* Old Selling Price */}
                <td>
                  {item.oldSellingPrice.map((oldPrice) => (
                    <div key={oldPrice._id}>
                      <p>
                        Threshold: {oldPrice.INR_threshold}, Charge:{" "}
                        {oldPrice.additionalCharge}%
                      </p>
                    </div>
                  ))}
                </td>

                {/* New Selling Price */}
                <td>
                  {item.newSellingPrice.map((newPrice) => (
                    <div key={newPrice._id}>
                      <p>
                        Threshold: {newPrice.INR_threshold}, Charge:{" "}
                        {newPrice.additionalCharge}%
                      </p>
                    </div>
                  ))}
                </td>

                <td>{item.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </section>
    </div>
  );
};
