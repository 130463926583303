import React from 'react'
import './header.css'
import { CiSearch, CiBellOn  } from "react-icons/ci";
import Avatar from "../../assets/images/avatar-1.png";
import { useLocation } from 'react-router-dom';
import { GoDotFill } from "react-icons/go";


const Header = () => {

  const location = useLocation();
  
  const HeadingName = () => {
    switch (location.pathname) {
      case '/dashboard':
        return ' ';
      case '/all-users':
        return 'All Users';
      case '/bank':
        return 'Bank';
      case '/deposit':
        return 'Deposit';
      case '/withdraw':
        return 'Withdraw';
      case '/transaction':
        return 'Transaction';
      case '/no-of-buyers':
        return 'Number Of Buyers';
      case '/no-of-seller':
        return 'Number Of Seller';
      case '/setting':
        return 'Setting';
      case '/currency-rate':
        return 'Currency Rate';
      default:
        return ' ';
    }
  };

  return (
    <>
    <div className='header-wrapper'>
        <div className='heading'>Dashboard <span className='heading-name'><GoDotFill /> {HeadingName()}</span></div> 
        <div className='header-items'>
            {/* <div className="search-bar-wrap">
              <input type="text" name='search' placeholder='Search...'/>
              <CiSearch className='icon'/>
            </div> */}

            {/* <div className="notification-bar">
              <span className='notification-no'>1</span>
              <CiBellOn className='icon-notification'/>
            </div> */}

            {/* <div className="profile-wrap">
              <img src={Avatar} alt="avatar"/>
            </div> */}
        </div>
    </div>
    </>
  )
}

export default Header
