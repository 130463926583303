import React from 'react'
import Header from '../components/Header/Header'
import SideNav from '../components/SideNavigation/SideNav'
import { TransactionPage } from '../components/Transaction/TransactionPage'
import { WithdrawTable } from '../components/WithdrawPage/WithdrawTable'

export const WithdrawPage = () => {
  return (
    <div>
         <>
            <Header />
            <SideNav />
            <WithdrawTable/>
        </>
    </div>
  )
}
