import React from "react";
import Header from "../Header/Header";
import SideNav from "../SideNavigation/SideNav";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GetBuyerDeatils } from "../../Api/Api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import BitCashlogo from "../../assets/images/bitcash-logo.png"

export const BuyerDetails = () => {
  const { id } = useParams(); // Extract the user ID from the URL
  const [user, setUser] = useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = useState(null);
  // const [isSuccess, setIsSuccess] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("Select");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await GetBuyerDeatils(id);
        setUser(response.data);
        console.log(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [id]); // Refetch details when the ID changes

  if (loading) return <div className="">Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!user) return <div>No user found.</div>;

  // Handle change event for radio buttons
  const handleStatusChange = (e) => {
    setPaymentStatus(e.target.value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const adminToken = sessionStorage.getItem("token");
    const accessToken = JSON.parse(adminToken);
    // console.log(accessToken)

    try {
      const response = await axios.patch(
        `https://backendbitcash.co.in/api/coinBuySell/updateBuyerStatus/${id}`,
        {
          status: paymentStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Include the token in the headers
          },
        }
      );

      if (response.status === 200) {
        toast.success("Payment Status Updated Successfully!");
        // setIsSuccess(true);

        // Redirect to another page after a delay
        setTimeout(() => {
          navigate("/no-of-buyers");
        }, 1000); // 1-second delay before redirect
      } else {
        toast.error("Failed to Update Payment Status.");
        // setIsSuccess(false);
      }
    } catch (error) {
      console.error("Error updating payment status:", error);
      toast.error("You Can Update Only Once.");
      // setIsSuccess(false);
    }
  };

  // conditon for CDM & Cash
  const renderConditionalInfo = () => {
    if (user.paymentOption === 'CASH') {
        return (
            <div>
                <p>Location :- {user.isCashPayment.cashLoactionId.address}</p>
            </div>
        );
    } else if (user.paymentOption === 'CDM') {
        return (
            <div>
                <p>Bank Name :- {user.isCDM_Payment.bankId.bankName}</p>
                <p>Account Number :- {user.isCDM_Payment.bankId.accountNumber}</p>
                <p>IFSC Code :- {user.isCDM_Payment.bankId.ifscCode}</p>
                <p>View Slip :- <a href={`https://backendbitcash.co.in/${user.isCDM_Payment.paymentSlip}`} target="_blank" rel="noopener noreferrer">View</a></p>
            </div>
        );
    } else {
        return <p>Payment type not specified.</p>;
    }
};

  return (
    <div>
      <Header />
      <SideNav />

      <section>
        <div className="user-details-container">
          <div className="user-details">
            <div className="user-details__info">
              <h3 style={{ marginBottom: "15px", marginLeft: "0px" }}>
                Buyer Details :-{" "}
              </h3>
              <h5 style={{ marginBottom: "15px" }}>
                Name :- {user.userId && user.userId.name ? user.userId.name : 'Unknown User'}
              </h5>
              <p>Email :- {user.userId && user.userId.email ? user.userId.email : 'Unknown User'}</p>
              <p>Mobile No. :- {user.userId && user.userId.mobile ? user.userId.mobile : 'Unknown User'}</p>
              <p>Coin :- {user.quantityUSDT} USDT</p>
              <p>Type :- {user.type}</p>
              <p>Payment Mode :- {user.paymentOption}</p>
              {renderConditionalInfo()}
              <p>Time & Date :- {user.createdAt}</p>
              <p>Status :- {user.status}</p>

              <form onSubmit={handleSubmit} className="d-flex">
                <div>
                  <label htmlFor="paymentStatus">Payment Status: </label>
                  <select
                    id="paymentStatus"
                    value={paymentStatus}
                    onChange={handleStatusChange}
                    className="select-style"
                  >
                    <option defaultValue disabled>
                      Select
                    </option>
                    <option value="Success">Success</option>
                    <option value="Failed">Failed</option>
                  </select>
                </div>
                <button
                  style={{ marginLeft: "25px", padding: "6px 10px" }}
                  className="transaction-status"
                  type="submit"
                >
                  Update Status
                </button>
              </form>
            </div>
            <div className="user-details__image">
              <img
                src={user.userId ? `https://backendbitcash.co.in${user.userId.image}` : BitCashlogo} style={{borderRadius: '50%'}} 
                alt="user"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
