import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import SideNav from "../../components/SideNavigation/SideNav";
import { GetPlatformPrice } from "../../Api/Api";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export const PlatformPriceFile = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  const [cash, setCash] = useState("");
  const [CDM, setCDM] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [thresholds, setThresholds] = useState([]);
  const [additionalCharges, setAdditionalCharges] = useState([]);

  const adminToken = sessionStorage.getItem("token");
  const accessToken = JSON.parse(adminToken);

  const fetchUsers = async () => {
    try {
      const response = await GetPlatformPrice();
      setUsers(response.data);

      setCash(response.data.buyingPrice?.cash || "");
      setCDM(response.data.buyingPrice?.CDM || "");
      setBasePrice(response.data.basePrice || "");

      setThresholds(
        response.data.sellingPrice?.map((sp) => sp.INR_threshold) || []
      );
      setAdditionalCharges(
        response.data.sellingPrice?.map((sp) => sp.additionalCharge) || []
      );
    } catch (err) {
      setError(err);
      console.error("Failed to fetch platform price:", err);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const validateData = (data) => {
    if (!data.cash || isNaN(data.cash)) {
      return "Cash value is required and must be a number.";
    }
    if (!data.CDM || isNaN(data.CDM)) {
      return "CDM value is required and must be a number.";
    }
    if (!data.basePrice || isNaN(data.basePrice)) {
      return "Base Price is required and must be a number.";
    }
    if (
      !data.INR_threshold ||
      !data.INR_threshold.split(",").every((threshold) => !isNaN(threshold))
    ) {
      return "INR Threshold is required and must be a comma-separated list of numbers.";
    }
    if (
      !data.additionalCharge ||
      !data.additionalCharge.split(",").every((charge) => !isNaN(charge))
    ) {
      return "Additional Charge is required and must be a comma-separated list of numbers.";
    }
    return null;
  };
  
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const updatedData = {
      cash,
      CDM,
      basePrice,
      INR_threshold: thresholds.join(","),
      additionalCharge: additionalCharges.join(","),
    };
    
    console.log( basePrice);
    console.log(88888, updatedData);

    const validationError = validateData(updatedData);
    if (validationError) {
      toast.error(validationError);
      return;
    }

    try {
      const response = await axios.patch(
        "https://backendbitcash.co.in/api/platformPrice/updatePlatformPrice/667d22f526c494e959554ccf",
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log(9999999999, response.data.data);

      toast.success("Platform Price Updated Successfully!");
      fetchUsers();
    } catch (err) {
      console.error("Failed to update platform price:", err);
      toast.error("Failed to update Platform Price");
      setError(err);
    }
  };

  const handleThresholdChange = (index, value) => {
    const newThresholds = [...thresholds];
    newThresholds[index] = value;
    setThresholds(newThresholds);
  };

  const handleAdditionalChargeChange = (index, value) => {
    const newAdditionalCharges = [...additionalCharges];
    newAdditionalCharges[index] = value;
    setAdditionalCharges(newAdditionalCharges);
  };

  return (
    <div>
      <Header />
      <SideNav />

      <section>
        <div>
          <div>
            <h4>Platform Price</h4>
          </div>
          <div className="d-flex">
            <div>
              <div className="platformprice_main">
                <div className="platformprice_prisehead">
                  {cash}-{CDM}
                </div>
                <div className="platformprice_cash d-flex justify-content-around">
                  <p>Cash</p>
                  <p>{cash}</p>
                </div>
                <div className="d-flex justify-content-around">
                  <p>CDM</p>
                  <p>{CDM}</p>
                </div>
              </div>
            </div>
            <div>
              <div className="platformprice_main">
                <div className="platformprice_prisehead">
                  Base Price :- {users.basePrice}
                </div>

                {users.sellingPrice?.map((priceDetail, index) => (
                  <div
                    key={priceDetail._id}
                    className="platformprice_cash d-flex justify-content-around"
                  >
                    <p>
                      {">"}= {priceDetail.INR_threshold}
                    </p>
                    <p>
                      {users.basePrice} +{" "}
                      <span className="platformprice_yellowtext">
                        {priceDetail.additionalCharge}
                      </span>
                    </p>
                    {/* <p>{priceDetail.price}</p> */}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Update platform price */}
          <div className="recent-transaction-wrapper" style={{ padding: "20px 30px",marginLeft: "20px",marginTop: "10px"}}>
            
            <h4>Update Platform Price</h4>
            <form onSubmit={handleSubmit}>

            <div className="row">
              <div className="col-lg-5">
              <div>
                <label>Cash :- &nbsp;</label>
                <input
                  type="text"
                  value={cash}
                  onChange={(e) => setCash(e.target.value)}
                  className="platformprice__input"
                  style={{ marginLeft: "60px" }}
                />
              </div>
              <br />
              <div>
                <label>CDM :- &nbsp;</label>
                <input
                  type="text"
                  value={CDM}
                  onChange={(e) => setCDM(e.target.value)}
                  className="platformprice__input"
                  style={{ marginLeft: "60px" }}
                />
              </div>
              <br />
              <div>
                <label>Base Price :- &nbsp;</label>
                <input
                  type="text"
                  value={basePrice}
                  onChange={(e) => setBasePrice(e.target.value)}
                  className="platformprice__input"
                />
              </div>
              <br />
              </div>
              <div className="col-lg-5">
                  {/* Render separate inputs for thresholds and additional charges */}
              {users.sellingPrice?.map((priceDetail, index) => (
                <div key={priceDetail._id}>
                  <label>INR Threshold {index + 1} :- &nbsp;</label>
                  <input
                    type="text"
                    value={thresholds[index] || ""}
                    onChange={(e) =>
                      handleThresholdChange(index, e.target.value)
                    }
                    className="platformprice__input"
                    style={{ marginLeft: "55px" }}
                  />
                  <br />
                  <label>Additional Charge {index + 1} :- &nbsp;</label>
                  <input
                    type="text"
                    value={additionalCharges[index] || ""}
                    onChange={(e) =>
                      handleAdditionalChargeChange(index, e.target.value)
                    }
                    className="platformprice__input mt-3 mb-3"
                  />
                  <br />
                </div>
              ))}
              </div>
            </div>

             

              

              <button className="transaction-status" style={{padding:"6px 10px"}} type="submit">Update Price</button>
            </form>
          </div>

          <br />
          <br />

          <div className="history-button-postion">
            <button className='transaction-status' style={{padding:"6px 10px"}}>
              <Link to="/platform-price-history" style={{color:"#000"}}>See all History</Link>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};
