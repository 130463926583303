import React from 'react'
import Header from '../components/Header/Header'
import SideNav from '../components/SideNavigation/SideNav'
import { CurrencyRate } from '../components/CurrencyRate/CurrencyRate'



export const CurrencyRateMain = () => {
  return (
    <>
        <Header />
        <SideNav />
        <CurrencyRate/>
    </>
  )
}
