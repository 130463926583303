import React from "react";
import MobileImage from "../assets/images/about-us-bitcoin.png";
import Coin3 from "../assets/images/Login/coin-3.png";
import Coin5 from "../assets/images/Login/coin-5.png";
import { Link } from "react-router-dom";
import Logo from "../assets/images/bitcash-logo.png";
import BannerImage from "../assets/images/close-up-hand-holding-coin.jpg"
import Crypto from "../assets/images/bitcash-home-coin.png"
import { TiTick } from "react-icons/ti";
import GoogleStore from "../assets/images/google_play.png"
import AppleStore from "../assets/images/apple-store.png"

export const ComingSoon = () => {
  
  return (
    <div>
      <div>

        <div>
            <div className="d-flex justify-content-between mt-3">
            <div className="logo-wrapper">
                <img src={Logo} alt=""/>
            </div>
            <div className="d-flex justify-content-evenly">
              {/* <div className="homepage-header">
                Home
              </div>
              <div className="homepage-header">
                About Us
              </div> */}
              {/* <div className="homepage-header">
                Contact Us
              </div> */}
            </div>
            {/* <div className="huelse-drexg">
                <Link to="/login">Login</Link>
            </div> */}
            </div>
        </div>

        {/* <div className="container-fluid" style={{marginTop:"30px"}}>
          <img src={BannerImage} alt="" width="100%" height="600px"/>
        </div> */}


        <div className="container">
          <div className="row">
            <div className="col-lg-6" style={{ display: "flex", alignItems: "center",justifyContent: "center"}}>
              {/* <div className="login__main-container"> */}
              <div className="">
                <div className="login__heding-box">
                  <h1 className="fs-40 sdlkfdjl">About <span style={{ color: "#e9c147" }}>Bitcash</span></h1>
                  {/* <p style={{textAlign: "justify"}}>At Bitcash, we are dedicated to revolutionizing the way you purchase, sell, and transfer Bitcoin. Whether you are a seasoned trader or a newcomer to the world of cryptocurrencies, Bitcash offers a user-friendly platform designed to meet your needs.</p> */}
                  <p style={{textAlign: "justify",color:"#e9e9e9"}}>At Bitcash, we are dedicated to revolutionizing the way you purchase, sell, and transfer Bitcoin. Our mission is to provide a seamless and secure platform that empowers users to take full control of their cryptocurrency transactions. Whether you are a seasoned trader with years of experience or a newcomer just starting your journey into the world of digital currencies, Bitcash is here to support you every step of the way.</p>

                  <p style={{textAlign: "justify",color:"#e9e9e9"}}>We understand that navigating the complexities of the cryptocurrency market can be challenging, which is why we've designed our platform to be intuitive and user-friendly. With Bitcash, you can enjoy an easy-to-use interface, real-time data, and tools that help you make informed decisions, whether you're buying your first Bitcoin or executing large-scale trades.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login__form-image mobile-center-text">
                <img src={MobileImage} alt="Mobile " width="80%" style={{borderRadius:"20px"}}/>
              </div>
            </div>
          </div>
          <div className="row mt-5">
          <div className="col-lg-6 order-2 order-lg-1">
              <div className="mt-5 mobile-center-text">
                <img src={Crypto} alt="Mobile " width="80%"  style={{borderRadius:"20px"}}/>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2" style={{ display: "flex", alignItems: "center",justifyContent: "center"}}>
              <div className="">
                <div className="">
                  <h1 className="fs-40 sdlkfdjl mo-mar-top-2075" style={{marginBottom:"20px"}}>Why <span style={{ color: "#e9c147" }}>Bitcash</span></h1>
                  {/* <p style={{textAlign: "justify"}}>At Bitcash, we are dedicated to revolutionizing the way you purchase, sell, and transfer Bitcoin. Whether you are a seasoned trader or a newcomer to the world of cryptocurrencies, Bitcash offers a user-friendly platform designed to meet your needs.</p> */}
                  <ul style={{textAlign: "justify",color:"#e9e9e9"}}>
                    <li><TiTick  className="TiTick"/>Bitcash is company which help user to earn daily passive income keeping a point in mind about users daily increasing expenses which needs a permanent solution.</li>
                    <li><TiTick  className="TiTick"/>We at Bitcash help keep user’s priorities first apart from some which aren’t in our hand.</li>
                    <li><TiTick  className="TiTick"/>We at Bitcash help user to get the best rates of the assets weather it comes to buying/selling one which user would be holding.</li>
                    <li><TiTick  className="TiTick"/>We provide user the simplest operating environment.</li>
                    <li><TiTick  className="TiTick"/>We provide user the best technical support & even the customer support in our working hours.</li>
                    <li><TiTick  className="TiTick"/>We will help user in every situation which user would be facing while using the application.</li>
                    <li><TiTick  className="TiTick"/>When it come on onboarding then why not the referral earning for that we provide flat 0.1% cashback on the first trade user make.</li>
                    <li><TiTick  className="TiTick"/>Apart from this Bitcash also provides VIP trades where user get the VIP level rates.</li>
                  </ul>
                </div>
              </div>
            </div>
            
          </div>

          <div className="row">
              <div className="col-lg-12">
                <div className="njdidjsdl">
                  <h3 style={{ fontSize: "20px", marginTop: "50px",marginBottom:"20px",textAlign: "center" }}>Download Our App From</h3>
                  <div className="d-flex justify-content-center">
                    
                    <a className="text-end" href="https://play.google.com/store/apps/details?id=com.bitcashapp" target="_blank">
                      <img src={GoogleStore} alt="" width="60%"/>
                    </a>
                    <a href="#" target="_blank">
                      <img src={AppleStore} alt="" width="60%" />
                    </a>

                  </div>
                </div>
              </div>
          </div>

          <div className="login-page-coin__wrapper">
            <img src={Coin3} alt="Coin" className="" />
          </div>

          <div className="login-page-coin__wrapper-2">
            <img src={Coin5} alt="" className="" />
          </div>
        </div>

          <div className="container">
              <div className="footer">
                <div className="d-flex">
                  <div className="footer-head"><Link to="privacy-policy" className="text-white">Privacy Policy</Link></div>
                  <div className="footer-head"><Link to="refund-and-return" className="text-white">Return & Refund</Link></div>
                  <div className="footer-head"><Link to="disclaimer" className="text-white">Disclaimer</Link></div>
                </div>
              </div>
          </div>

      </div>
    </div>
  );
};
