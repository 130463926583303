import React from 'react'
import Header from '../components/Header/Header'
import SideNav from '../components/SideNavigation/SideNav'
import { BuyersList } from '../components/BuyerPage/BuyersList'
// import '../components/Dashboard/responsive.css'


export const NumberOfBuyers = () => {
  return (
    <>
        <Header />
        <SideNav />
        <BuyersList/>
    </>
  )
}
