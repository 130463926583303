import React from 'react'
import Table from 'react-bootstrap/Table';
import './transactionpage.css'
import FromImageName from "../../assets/images/user-1.png";
import ToImageName from "../../assets/images/user-2.png";
import DollarLogo from "../../assets/images/b-dollar-logo.png";
import EthereumLogo from "../../assets/images/t-icon.png";
import MoneroLogo from "../../assets/images/d-icon.png";


const Data = [
  {
      id : 1,
      transaction_id : "#166565955",
      date: "15-05-2024",
      from : "Shyam",
      fromImg:FromImageName,
      to: "Ravi",
      toImg:ToImageName,
      coin: "Ethereum",
      coinImage: EthereumLogo,
      amount: "$455.30",
      status: "Completed",
  },
  {
      id : 2,
      transaction_id : "#166565955",
      date: "15-05-2024",
      from : "Shyam",
      fromImg:FromImageName,
      to: "Ravi",
      toImg:ToImageName,
      coin: "Ethereum",
      coinImage: EthereumLogo,
      amount: "$455.30",
      status: "Completed",
  },
  {
      id : 3,
      transaction_id : "#166565955",
      date: "15-05-2024",
      from : "Shyam",
      fromImg:FromImageName,
      to: "Ravi",
      toImg:ToImageName,
      coin: "Bitcoin",
      coinImage: DollarLogo,
      amount: "$455.30",
      status: "Completed",
  },
  {
      id : 4,
      transaction_id : "#166565955",
      date: "15-05-2024",
      from : "Shyam",
      fromImg:FromImageName,
      to: "Ravi",
      toImg:ToImageName,
      coin: "Monero",
      coinImage: MoneroLogo,
      amount: "$455.30",
      status: "Completed",
  },
  {
      id : 5,
      transaction_id : "#166565955",
      date: "15-05-2024",
      from : "Shyam",
      fromImg:FromImageName,
      to: "Ravi",
      toImg:ToImageName,
      coin: "Ethereum",
      coinImage: EthereumLogo,
      amount: "$455.30",
      status: "Completed",
  },
  {
      id : 6,
      transaction_id : "#166565955",
      date: "15-05-2024",
      from : "Shyam",
      fromImg:FromImageName,
      to: "Ravi",
      toImg:ToImageName,
      coin: "Bitcoin",
      coinImage: DollarLogo,
      amount: "$455.30",
      status: "Completed",
  },
  {
      id : 7,
      transaction_id : "#166565955",
      date: "15-05-2024",
      from : "Shyam",
      fromImg:FromImageName,
      to: "Ravi",
      toImg:ToImageName,
      coin: "Ethereum",
      coinImage: EthereumLogo,
      amount: "$455.30",
      status: "Completed",
  },
  {
      id : 8,
      transaction_id : "#166565955",
      date: "15-05-2024",
      from : "Shyam",
      fromImg:FromImageName,
      to: "Ravi",
      toImg:ToImageName,
      coin: "Bitcoin",
      coinImage: DollarLogo,
      amount: "$455.30",
      status: "Completed",
  },
  {
      id : 9,
      transaction_id : "#166565955",
      date: "15-05-2024",
      from : "Shyam",
      fromImg:FromImageName,
      to: "Ravi",
      toImg:ToImageName,
      coin: "Ethereum",
      coinImage: EthereumLogo,
      amount: "$455.30",
      status: "Completed",
  },
  {
      id : 10,
      transaction_id : "#166565955",
      date: "15-05-2024",
      from : "Shyam",
      fromImg:FromImageName,
      to: "Ravi",
      toImg:ToImageName,
      coin: "Bitcoin",
      coinImage: DollarLogo,
      amount: "$455.30",
      status: "Completed",
  },
  {
      id : 11,
      transaction_id : "#166565955",
      date: "15-05-2024",
      from : "Shyam",
      fromImg:FromImageName,
      to: "Ravi",
      toImg:ToImageName,
      coin: "Ethereum",
      coinImage: EthereumLogo,
      amount: "$455.30",
      status: "Completed",
  },
]

export const TransactionPage = () => {
  return (
    <section>
        <Table bordered hover responsive>
          <thead style={{backgroundColor: '#151515'}}>
            <tr className='table-head'>
              <th>Transaction ID</th>
              <th>Date</th>
              <th>From</th>
              <th>To</th>
              <th>Coin</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody >
          {Data.map((item)=>(
            <tr className='table-body' key={item.id} id={item.id}>
              <td>{item.transaction_id}</td>
              <td>{item.date}</td>
              <td><img src={item.fromImg} alt='' width='30px' height='30px'/> {item.from}</td>
              <td><img src={item.toImg} alt='' width='30px' height='30px'/> {item.to}</td>
              <td><img src={item.coinImage} alt='' width='30px' height='30px'/> {item.coin}</td>
              <td>{item.amount}</td>
              <td><span className='transaction-status'>{item.status}</span></td>
            </tr>
            ))}
          </tbody>
    </Table>
    </section>
  )
}
