import React from 'react'
import Table from 'react-bootstrap/Table';
import '../Transaction/transactionpage.css'
import FromImageName from "../../assets/images/user-1.png";
import ToImageName from "../../assets/images/user-2.png";
import DollarLogo from "../../assets/images/b-dollar-logo.png";
import EthereumLogo from "../../assets/images/t-icon.png";
import MoneroLogo from "../../assets/images/d-icon.png";




const Data = [
    {
        id : 1,
        currency_name : "Ethereum Purchased",
        Currency_image:DollarLogo,
        amount:"$457.00",
        total:"$457.00",
        date: "15-05-2024",
        status: "Completed",
    },
    {
        id : 2,
        currency_name : "Bitcoin Purchased",
        Currency_image:EthereumLogo,
        amount:"$457.00",
        total:"$457.00",
        date: "15-05-2024",
        status: "Completed",
    },
    {
        id : 3,
        currency_name : "Ethereum Purchased",
        Currency_image:MoneroLogo,
        amount:"$457.00",
        total:"$457.00",
        date: "15-05-2024",
        status: "Completed",
    },
    {
        id : 4,
        currency_name : "Bitcoin Purchased",
        Currency_image:DollarLogo,
        amount:"$457.00",
        total:"$457.00",
        date: "15-05-2024",
        status: "Completed",
    },
    {
        id : 5,
        currency_name : "Ethereum Purchased",
        Currency_image:EthereumLogo,
        amount:"$457.00",
        total:"$457.00",
        date: "15-05-2024",
        status: "Completed",
    },
  ]
  
  export const CurrencyRate = () => {
    return (
      <section>
      <Table bordered hover responsive>
        <thead style={{backgroundColor: '#151515'}}>
          <tr className='table-head'>
            <th>Currancy Rate</th>
            <th>Amount</th>
            <th>Total</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody >
        {Data.map((item)=>(
          <tr className='table-body' key={item.id} id={item.id}>
            <td><img src={item.Currency_image} alt='' width='30px' height='30px'/> {item.currency_name}</td>
            <td>{item.amount}</td>
            <td>{item.total}</td>
            <td>{item.date}</td>
            <td><span className='transaction-status'>{item.status}</span></td>
          </tr>
          ))}
        </tbody>
  </Table>
  </section>
    )
  }