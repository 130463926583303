import React from "react";
import MobileImage from "../../assets/images/Login/dizzy-payment-card-with-coins.gif";
import Coin3 from "../../assets/images/Login/coin-3.png";
import Coin5 from "../../assets/images/Login/coin-5.png";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/bitcash-logo.png";
import BannerImage from "../../assets/images/close-up-hand-holding-coin.jpg"
import Crypto from "../../assets/images/crypto.jpg"
import { TiTick } from "react-icons/ti";

export const Disclaminer = () => {
  
  return (
    <div>
      <div>

        <div>
            <div className="d-flex justify-content-between mt-3">
            <div className="logo-wrapper">
                <img src={Logo} alt=""/>
            </div>
            <div className="d-flex justify-content-evenly">
              
            </div>
            {/* <div className="huelse-drexg">
                <Link to="/login">Login</Link>
            </div> */}
            </div>
        </div>

     


        <div className="container">
          
          <div className="row">
          
            <div className="col-lg-12" style={{ display: "flex", alignItems: "center",justifyContent: "center"}}>
              <div className="">
                <div className="login__heding-box">
                  <h1 className="fs-40 sdlkfdjl-2" style={{marginBottom:"20px",marginTop:"40px"}}>Disclaimer <span style={{ color: "#e9c147" }}>& Termination </span></h1>
                  <p style={{textAlign: "justify",color:"white"}}>This privacy policies are made for the user of BITCASH to know about their own benefits & even to co-operate with the BITCASH COMPANY & COMMUNITY which is been registered as/represented as FINEX HUB TECHNOLOGIES PVT. LTD.</p>
                  
                <h5>Termination Of Account</h5>

                <p style={{textAlign: "justify",color:"white"}}>User has the whole right to delete / terminate Bitcash account according to users will but the whole process will take 90 business days if the user activates the account the termination of account will be cancelled, & user must proceed new termination request</p>

                <h5>Usage & Disclamer  </h5>

                <p style={{textAlign: "justify",color:"white"}}>Bitcash is only for the users whose age is above 18 if not then the user will be whole & sole responsible of his activities </p>
                <p style={{textAlign: "justify",color:"white"}}>Bitcash has full authority to terminate as well as restrict the users account if they found doing any wrong and misappropriate activities by using the application </p>
                </div>
              </div>
            </div>
            
          </div>

          <div className="login-page-coin__wrapper">
            <img src={Coin3} alt="Coin" className="" />
          </div>

          <div className="login-page-coin__wrapper-2">
            <img src={Coin5} alt="" className="" />
          </div>
        </div>

          {/* <div className="container">
              <div className="footer">
                <div className="d-flex">
                <div className="footer-head"><Link to="privacy-policy" className="text-white">Privacy Policy</Link></div>
                  <div className="footer-head">Terms & Conditions</div>
                </div>
              </div>
          </div> */}

      </div>
    </div>
  );
};
