import React, { useState, useEffect } from 'react';
import { getTotalDepositDetail } from '../../Api/Api';
import { TbMoneybag } from "react-icons/tb";

const Deposit = () => {


  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getTotalDepositDetail();
        setUsers(response.data);
        // console.log(response.data);
      } catch (err) {
        setError(err);
      }
    };

    fetchUsers();
  }, []);



  return (
    <>
      <div className="card-wrapper">
        <div className="card-icon-wrap">
            <div className="card-icon-body color">
               <TbMoneybag className='card-icon'/>
            </div>
        </div>
        <div className='card-text-wrap'>
            <h4>Total Deposit</h4>
            <h2>$ {users.totalDepositMoney}</h2>

        </div>
      </div>
      
    </>
  )
}

export default Deposit
