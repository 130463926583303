import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import '../Transaction/transactionpage.css'
import FromImageName from "../../assets/images/user-1.png";
import ToImageName from "../../assets/images/user-2.png";
import DollarLogo from "../../assets/images/b-dollar-logo.png";
import EthereumLogo from "../../assets/images/t-icon.png";
import MoneroLogo from "../../assets/images/d-icon.png";
import { UsersWithdrawalRequest } from '../../Api/Api';
import { Link } from 'react-router-dom';
import BitCashlogo from "../../assets/images/bitcash-logo.png"




export const WithdrawTable = () => {

  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

      useEffect(() => {
        const fetchUsers = async () => {
          try {
            const response = await UsersWithdrawalRequest();
            setUsers(response.data);
            console.log(response.data);
          } catch (err) {
            setError(err);
          }
        };

        fetchUsers();
      }, []);


  return (
    <section>
    <Table bordered hover responsive>
      <thead style={{backgroundColor: '#151515'}}>
        <tr className='table-head'>
          <th>Profile</th>
          <th>Name</th>
          <th>Amount</th>
          <th>Date & Time</th>
          <th>Transaction</th>
          <th>Payment Mode</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody >
      {users.map((item)=>(
        <tr className='table-body' key={item.id} id={item.id}>
          <td><img src={item.userId && item.userId.image ? `https://backendbitcash.co.in${item.userId.image}` : BitCashlogo} style={{borderRadius: '50%'}}  height={40} width={40}></img></td>
          <td>{item.userId && item.userId.name}</td>
          <td>₹ {item.INR_Amount}</td>
          <td>{item.createdAt}</td>
          <td>{item.type}</td>
          <td>{item.transactionType}</td>
          <td>{item.status}</td>
          <td><Link to={`/withdraw-money-details/${item.id}`}><span className='transaction-status'>View</span></Link></td>
        </tr>
        ))}
      </tbody>
</Table>
</section>
  )
}
