import React, { useEffect, useState }from 'react'
import Table from 'react-bootstrap/Table';
import '../Transaction/transactionpage.css'
import { GetBuyerList } from '../../Api/Api';
import { Link } from 'react-router-dom';
import BitCashlogo from "../../assets/images/bitcash-logo.png"

export const BuyersList = () => {


  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [visiblePages, setVisiblePages] = useState([1, 2]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await GetBuyerList();
        setUsers(response.data);
        console.log(response.data);
      } catch (err) {
        setError(err);
      }
    };

    fetchUsers();
  }, []);

   // Logic for displaying users per page
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  // Total number of pages
  const totalPages = Math.ceil(users.length / itemsPerPage);

  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);

    // Update visible pages
    if (pageNumber <= 2) {
      setVisiblePages([1, 2, 3]);
    } else if (pageNumber >= totalPages - 1) {
      setVisiblePages([totalPages - 2, totalPages - 1, totalPages]);
    } else {
      setVisiblePages([pageNumber - 1, pageNumber, pageNumber + 1]);
    }
  };

  // Function to go to next page
  const nextPage = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  // Function to go to previous page
  const prevPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };


  return (
    <section>
    <Table bordered hover responsive>
      <thead style={{backgroundColor: '#151515'}}>
        <tr className='table-head'>
          <th>Buyer Profile</th>
          <th>Buyer Name</th>
          <th>Mobile No.</th>
          <th>Coins</th>
          <th>Date & Time</th>
          <th>Paymant Mode</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody >
      {error ? (
          <tr>
            <td colSpan="8" style={{ textAlign: 'center', color: 'red' }}>
              Error: {error.message || 'Failed to load data'}
            </td>
          </tr>
        ) : currentUsers.length === 0 ? (
          <tr>
            <td colSpan="8" className='no-data-found'>
              No Data Found
            </td>
          </tr>
        ) : (
          currentUsers.map((item)=>(
        <tr className='table-body' key={item.id} id={item.id}>
          {/* <td><img src={item.userId && item.userId.image ? item.userId.image : 'https://avatar.iran.liara.run/public'} height={40} width={40}></img></td> */}
          <td><img src={item.userId && item.userId.image ? `https://backendbitcash.co.in${item.userId.image}` : BitCashlogo} style={{borderRadius: '50%'}} height={40} width={40}></img></td>
          <td>{item.userId && item.userId.name ? item.userId.name : 'Unknown User'}</td>
          <td>{item.userId && item.userId.mobile}</td>
          <td>{item.quantityUSDT} USDT</td>
          <td>{item.createdAt}</td>
          <td>{item.paymentOption}</td>
          <td>{item.status}</td>
          <td><Link to={`/buyer-details/${item.id}`}><span className='transaction-status'>View</span></Link></td>
        </tr>
        )))}
      </tbody>
</Table>

      {/* Pagination */}
      {/* <nav>
        <ul className='pagination'>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a onClick={prevPage} className='page-link'>
              &laquo;
            </a>
          </li>
          {visiblePages.map((number) => (
            <li
              key={number}
              className={`page-item ${currentPage === number ? 'active' : ''}`}
            >
              <a onClick={() => paginate(number)} className='page-link'>
                {number}
              </a>
            </li>
          ))}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a onClick={nextPage} className='page-link'>
              &raquo;
            </a>
          </li>
        </ul>
      </nav> */}

</section>
  )
}
