import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import DashBoard from './Pages/DashBoard'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NumberOfBuyers } from './Pages/NumberOfBuyers';
import { NumberOfSeller } from './Pages/NumberOfSeller';
import { Login } from './Pages/Login';
import { ForgotPassword } from './Pages/ForgotPassword';
import { Transaction } from './Pages/Transaction';
import { DepositMainPage } from './Pages/DepositMainPage';
import { WithdrawPage } from './Pages/WithdrawPage';
import { Setting } from './Pages/Setting';
import { ToastContainer } from 'react-toastify';
import { ComingSoon } from './Pages/ComingSoon';
import { CurrencyRateMain } from './Pages/CurrencyRateMain';
import { AllUsers } from './Pages/AllUsers';
import { BankListPage } from './Pages/BankListPage';
import { AllUserDetails } from './components/AllUsersPage/AllUserDetails';
import { ProtectedRoute } from './Auth/ProtectedRoute';
import { BuyerDetails } from './components/BuyerPage/BuyerDetails';
import { SellerDetails } from './components/SellerPage/SellerDetails';
import { CoinDeposit } from './Pages/CoinDeposit';
import { CoinDepositDetails } from './components/CoinDeposit/CoinDepositDetails';
import { DepositMoneyDetails } from './components/DepositPage/DepositMoneyDetails';
import { WithdrawDetailsUser } from './components/WithdrawPage/WithdrawDetailsUser';
import { PlatformPrice } from './Pages/PlatformPrice';
import { CoinValue } from './Pages/CoinValue';
import {PlatformPriceHistory} from './components/PlatformPricefol/PlatformPriceHistory';
import {CoinValueHistory} from './components/CoinValuefol/CoinValueHistory';
import { PrivacyPolicy } from './Pages/Policies/PrivacyPolicy';
import { ReturnRefund } from './Pages/Policies/ReturnRefund';
import { Disclaminer } from './Pages/Policies/Disclaminer';


function App() {

  return (
    <>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<ComingSoon/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path="/refund-and-return" element={<ReturnRefund/>} />
            <Route path="/disclaimer" element={<Disclaminer/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/dashboard" element={<ProtectedRoute><DashBoard/></ProtectedRoute>} />
            <Route path="/no-of-buyers" element={<ProtectedRoute><NumberOfBuyers/></ProtectedRoute>} />
            <Route path="/no-of-seller" element={<ProtectedRoute><NumberOfSeller/></ProtectedRoute>} />
            <Route path="/forgot-password" element={<ProtectedRoute><ForgotPassword/></ProtectedRoute>} />
            <Route path="/transaction" element={<ProtectedRoute><Transaction/></ProtectedRoute>} />
            <Route path="/deposit" element={<ProtectedRoute><DepositMainPage/></ProtectedRoute>} />
            <Route path="/withdraw" element={<ProtectedRoute><WithdrawPage/></ProtectedRoute>} />
            <Route path="/all-users" element={<ProtectedRoute><AllUsers/></ProtectedRoute>} />
            <Route path="/currency-rate" element={<ProtectedRoute><CurrencyRateMain/></ProtectedRoute>} />
            <Route path="/setting" element={<ProtectedRoute><Setting/></ProtectedRoute>} />
            <Route path="/bank" element={<ProtectedRoute><BankListPage/></ProtectedRoute>} />
            <Route path="/coin-deposit" element={<ProtectedRoute><CoinDeposit/></ProtectedRoute>} />
            <Route path="/platform-price" element={<ProtectedRoute><PlatformPrice/></ProtectedRoute>} />
            <Route path="/platform-price-history" element={<ProtectedRoute><PlatformPriceHistory/></ProtectedRoute>} />
            <Route path="/coin-value" element={<ProtectedRoute><CoinValue/></ProtectedRoute>} />
            <Route path="/coin-price-history" element={<ProtectedRoute><CoinValueHistory/></ProtectedRoute>} />

            <Route path="/all-users-details/:id" element={<ProtectedRoute><AllUserDetails/></ProtectedRoute>} />
            <Route path="/buyer-details/:id" element={<ProtectedRoute><BuyerDetails/></ProtectedRoute>} />
            <Route path="/seller-details/:id" element={<ProtectedRoute><SellerDetails/></ProtectedRoute>} />
            <Route path="/coin-deposit-details/:id" element={<ProtectedRoute><CoinDepositDetails/></ProtectedRoute>} />
            <Route path="/deposit-money-details/:id" element={<ProtectedRoute><DepositMoneyDetails/></ProtectedRoute>} />
            <Route path="/withdraw-money-details/:id" element={<ProtectedRoute><WithdrawDetailsUser/></ProtectedRoute>} />




            
            <Route path="/exchange-platform-price" element={<h1>Hello</h1>} />
          </Routes>
      </BrowserRouter>
      <ToastContainer/>
    </>
  )
}

export default App
