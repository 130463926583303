import React from 'react'
import Header from '../components/Header/Header'
import SideNav from '../components/SideNavigation/SideNav'
import { SellerList } from '../components/SellerPage/SellerList'
// import '../components/Dashboard/responsive.css'


export const NumberOfSeller = () => {
  return (
    <>
        <Header />
        <SideNav />
        <SellerList/>
    </>
  )
}
