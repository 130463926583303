import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { totalUserList } from '../../Api/Api';
import BitCashlogo from "../../assets/images/bitcash-logo.png"

const RecentTransaction = () => {

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await totalUserList();
        setUsers(response.data.slice(0, 5));
        // console.log(response.data.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) return <div><h3 className='Loading-name'>Loading...</h3></div>;
  if (error) return <div>Error: {error.message}</div>;



  return (
    <>
     <div className="transaction-heading-wrapper">
        <h4>Recent Registration</h4>
        <h5><Link to="/all-users">See all</Link></h5>
      </div>

      <div className="transaction-list">
        {users.map((user) => (
        <div className="list-item" key={user.id} id={user.id}>
          <div className="list-profile">
            <div className="list-image-wrap">
            
              <img
                src={user.image ? `https://backendbitcash.co.in${user.image}` : BitCashlogo} style={{borderRadius: '50%'}} 
                alt="user"
              />
            </div>

            <div className="transaction-information-wrap">
              <h3>{user.name}</h3>
              <h5>{user.email}</h5>
            </div>
          </div>

          <div className="transaction-amt">
            <h4>Mobile No :- {user.mobile}</h4>
            <h6>{user.createdAt}</h6>
          </div>
        </div>
          ))}
      </div>
    </>
  )
}

export default RecentTransaction
