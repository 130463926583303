import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GoPeople } from "react-icons/go";
import { Link } from 'react-router-dom';
import { totalUserList } from '../../Api/Api';

const TotalMembers = () => {

  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await totalUserList();
        setUsers(response);
        // console.log(response);
      } catch (err) {
        setError(err);
      }
    };
    fetchUsers();
  }, []);


  return (
    <>
    <div className="card-wrapper">
        <div className="card-icon-wrap">
            <div className="card-icon-body">
               <GoPeople className='card-icon'/>
            </div>
        </div>
        <div className='card-text-wrap'>
            <h4>Total Users</h4>
            <h2>
                <h2><Link to={"/all-users"}>{users.totalMembers}</Link></h2>
            </h2>
        </div>
      </div>
    </>
  )
}

export default TotalMembers
